import React, {Component, useEffect, useRef, useState} from "react";
import {Location, User, Visit} from "../types/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";

interface Props {
    user: User | undefined;
}

function UserDetails(props: Props) {
    return (
        <>
            {props.user && <div className={"user-header"}>
                {props.user && <div>{props.user.name}</div>}
                <div style={{marginTop: "-0.4em"}}>
                    {!props.user && <div>...</div>}
                    {(props.user && props.user.visitCount > 0) && <div className={"d-inline-block"} style={{fontSize: "0.5em"}}><b>{props.user.visitCount}</b> visits</div>}
                    {(props.user && !props.user.visitCount) && <div className={"d-inline-block"} style={{fontSize: "0.5em"}}><b>0</b> visits</div>}
                    {(props.user && props.user.sessionVerifiedCount > 0) &&
                        <div className={"d-inline-block ml-1"} style={{fontSize: "0.5em"}}>- <b>{props.user.sessionVerifiedCount}</b> <FontAwesomeIcon icon={faLocationDot} /></div>}
                </div>
            </div>}
        </>
    );
}

export default UserDetails;
import {
  faCog, faLocationDot,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PureComponent } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import history from "../util/History";
import {Session, User} from "../types/Types";
import {db} from "../../firebase/FirebaseProvider"
import {doc, setDoc, getDoc, query, collection, where, getDocs} from 'firebase/firestore'
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";

interface Props {
  userSession: Session | undefined;
  sessions: Array<Session> | undefined;
}

interface State {
  currentUser?: User | null;
}

class Header extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        return;
      }
      const userCollection = query(collection(db, 'users'), where('authId', '==', currentUser.uid));
      const userCollectionSnap = await getDocs(userCollection);
      userCollectionSnap.forEach((userDoc) => {
        let userToSet =  userDoc.data() as User;
        userToSet.id = userDoc.id;
        this.setState({ currentUser: userToSet });
        return;
      });
    });
  }

  public render() {
    const { currentUser } = this.state;

    return (
      <Navbar expand variant="dark" sticky="top" bg="dark">
        <Container>
          <Navbar.Brand href="/home">'Spoons Tracker</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {(currentUser && this.props.sessions && this.props.sessions.length > 0) && (
                  <Nav.Item style={{marginRight: "-8px"}}>
                    <Link to={`/hub/sessions`} className="nav-link cursor-pointer">
                      <div className={`user-tag active-session`}>
                        <div className={""}>
                          <FontAwesomeIcon icon={faLocationDot} />
                          <div className={"d-inline-block ml-1"}>{this.props.sessions.length}</div>
                        </div>
                      </div>
                    </Link>
                  </Nav.Item>
              )}
              {currentUser && (
                <Nav.Item>
                  <Link to={`/profile/${currentUser.id}/visits/me`} className="nav-link cursor-pointer">
                    <div className={`user-tag ${this.props.userSession ? "active-session" : ""}`}>{currentUser.name}</div>
                  </Link>
                </Nav.Item>
              )}
              {currentUser && (
                  <Nav.Item>
                    <Link to={`/settings`} className="nav-link cursor-pointer">
                      <FontAwesomeIcon icon={faCog} />
                    </Link>
                  </Nav.Item>
              )}
              {currentUser && (
                <Nav.Item>
                  <Button
                    onClick={this.logout}
                    className="nav-link"
                    variant="link"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </Button>
                </Nav.Item>
              )}
              {!currentUser && (
                <Nav.Item>
                  <Link to="/" className="nav-link">
                    Login <FontAwesomeIcon icon={faSignInAlt} />
                  </Link>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  private logout = () => {
    const auth = getAuth();
    signOut(auth)
        .then(() => {
          this.setState({currentUser: undefined});
          history.push("/");
        });
  }
}

export default Header;

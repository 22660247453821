import React from "react";
import "../nav/Navigation.css";
import { NavLink, RouteComponentProps } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

function HubNav() {

    return (
        <Container>
            <Nav variant="tabs" className="mb-2 mr-auto sub-menu-nav">
                <>
                    <NavLink className="nav-link" to="/hub/visits">
                        Visits
                    </NavLink>
                    <NavLink className="nav-link" to="/hub/sessions">
                        Check-ins
                    </NavLink>
                    <NavLink className="nav-link" to="/hub/comments">
                        Comments
                    </NavLink>
                    <NavLink className="nav-link" to="/hub/updates">
                        Updates
                    </NavLink>
                </>
            </Nav>
        </Container>
    )
}

export default HubNav;

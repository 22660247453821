import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {User} from "../types/Types";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import history from "../util/History";
import {faBeer, faHammer, faPlane, faHotel, faTimes, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp, faThumbsDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import FaSymbol from "./components/FaSymbol";
import ImageSymbol from "./components/ImageSymbol";

interface Props {
    currentUser: User | undefined
}

function Home(props: Props) {

    useEffect(() => {
        (async () => {
            const auth = getAuth();
            onAuthStateChanged(auth, async (currentUser) => {
                if (!currentUser) {
                    history.push('/');
                    return;
                }
            });
        })();
    }, []);

    return (
        <>
            {props.currentUser && <>
                <Card>
                    <Card.Header>
                        <div>Welcome to Spoons' Tracker {props.currentUser && <strong>{props.currentUser.name}!</strong>}</div>
                    </Card.Header>
                    <Card.Body>
                        <ul>
                            <li><div className={"mb-2"}>Please navigate to the <Link to={"/pubs"}>Pubs</Link> page to start marking off your visits.</div></li>
                            <li><div className={"mb-2"}>Click on the <Link to={"/location/a1f44f247-298d-4303-b4ea-f373ae2ecacf/comments"}>pub name</Link> to get more information, add comments, check in and to see who else has visited.</div></li>
                            <li><div className={"mb-2"}>The <Link to={"/hub/visits"}>Hub</Link> will provide a news feed of whats happening at each location.</div></li>
                            <li><div className={"mb-2"}>Use the <Link to={"/map"}>Map</Link> to get clear view of where you have visited.</div></li>
                            <li><div className={"mb-2"}>Get a list of pubs you've visited from your {props.currentUser && <Link to={`/profile/${props.currentUser.id}/visits`}>user profile</Link>}{!props.currentUser && <span>user profile</span>}.</div></li>
                            <li><div className={"mb-2"}>See how you size up against other users on the <Link to={"/leaderboard"}>Leaderboard</Link> page.</div></li>
                            <li><div className={"mb-2"}>Visit the <Link to={"/settings"}>settings</Link> page to configure your account and set a password.</div></li>
                            <li><div className={"mb-2"}>Use the filter and sort options to decide where to visit next!</div></li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className={"mt-3"}>
                    <Card.Header>
                        <div>Symbols used on the site:</div>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <FaSymbol class={"icon-open"} faIcon={faBeer} description={"Pub is open"}/>
                            <FaSymbol class={"icon-temporary"} faIcon={faHammer} description={"Pub is renovating"}/>
                            <FaSymbol class={"icon-closed"} faIcon={faTimes} description={"Pub is closed"}/>
                            <FaSymbol class={"icon-plane"} faIcon={faPlane} description={"Pub is in an airport"}/>
                            <FaSymbol class={"icon-hotel"} faIcon={faHotel} description={"Pub is a hotel"}/>
                        </div>
                        <div>
                            <FaSymbol class={"white"} faIcon={faCheckCircle} description={"Visit a pub"}/>
                            <FaSymbol class={"white"} faIcon={faLocationDot} description={"Verified visit"}/>
                        </div>
                        <div>
                            <ImageSymbol class={"white"} image={"/images/visited-marker.png"} description={"Visited pub"}/>
                            <ImageSymbol class={"white"} image={"/images/visited-marker-active2.png"} description={"Visited pub (active check ins)"}/>
                            <ImageSymbol class={"white"} image={"/images/new-marker.png"} description={"Not visited pub"}/>
                            <ImageSymbol class={"white"} image={"/images/new-marker-active2.png"} description={"Not visited pub (active check-ins)"}/>
                        </div>
                    </Card.Body>
                </Card>
            </>}
        </>
    )
}

export default Home;
import React, {Component, useEffect, useState} from "react";
import {Card, ListGroup} from "react-bootstrap";
import {Location, Comment, NewsItem, User, CommentInfo} from "../../types/Types";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db, functions} from "../../../firebase/FirebaseProvider";
import LocationNav from "./LocationNav";
import LocationDetails from "./LocationDetails";
import CommentRow from "./CommentRow";
import {httpsCallable} from "@firebase/functions";
import {NOTIFICATION_LIMIT, sanitizeHtmlString} from "../../util/Util";
import LocationCheckIn from "./LocationCheckIn";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    currentUser: User | undefined,
    usersMap: Map<string, User>
}

function LocationComments(props: Props) {

    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [comment, setComment] = useState<string | undefined>();
    const [commentSending, setCommentSending] = useState<boolean>(false);
    const [comments, setComments] = useState<Array<NewsItem> | undefined>(undefined);
    const [feedback, setFeedback] = useState<string>();

    useEffect(() => {
        (async () => {
            const locationRef = doc(db, 'locations', props.match.params.id);
            const docSnap = await getDoc(locationRef);
            if (docSnap.exists()) {
                let locationToSet = docSnap.data() as Location;
                locationToSet.id = docSnap.id;
                setLocation(locationToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadComments();
        })();
    }, [location]);

    const loadComments = async() => {
        if(!location) {
            return;
        }

        // Load comments
        let comments: Array<NewsItem> = [];
        const commentsOnceCollection = query(collection(db, 'comments'),
            where('locationId', '==', location.id),
            orderBy('lastModified', 'desc'),
            limit(NOTIFICATION_LIMIT));
        const commentsOnceCollectionSnapshot = await getDocs(commentsOnceCollection);
        commentsOnceCollectionSnapshot.forEach((doc) => {
            let tempComment = doc.data() as NewsItem;
            tempComment.id = doc.id;
            comments.push(tempComment);
        });
        setComments(comments);

    }

    const saveComment = () => {
        if(!location || !comment) {
            return;
        }

        const sanitizedString = sanitizeHtmlString(comment);
        if(!sanitizedString) {
            return;
        }

        setCommentSending(true);

        var saveComment = httpsCallable(functions, 'saveComment');
        saveComment({comment: {
                locationId: location.id,
                locationName: location.name,
                payload: sanitizedString,
                lastModified: new Date()
            }}).then(r => {
                setFeedback("Successfully saved comment.")
                setComment("");
                setCommentSending(false);
                loadComments();
        });
    }

    const deleteComment = (commentId: string): void => {
        var deleteComment = httpsCallable(functions, 'deleteComment');
        deleteComment({commentInfo: {commentId: commentId}}).then(r => {
            loadComments();
        });
    }

    return (
        <>
            <LocationDetails location={location}/>
            {location && props.currentUser && !props.currentUser.socialDisabled && <LocationCheckIn currentUser={props.currentUser} location={location}/>}
            {location && <LocationNav locationId={location.id}/>}
            {location && props.currentUser && !props.currentUser.socialDisabled && <Card className={"mb-2"}>
                <div style={{padding: "0.8em"}}>
                    <div className="form-group p-0">
                        <div>
                            <textarea placeholder={"Type your comment here"} className="form-control" value={comment} id="add-comment" onChange={e => {setComment(e.target.value); setFeedback("")}}></textarea>
                        </div>
                        <div>
                            <div style={{float: "left", marginTop: "0.6em"}}>
                                <span>{feedback}</span>
                            </div>
                            <div className={""} style={{float: "right", marginTop: "0.6em"}}>
                                <button disabled={commentSending} onClick={() => saveComment()} className={"btn btn-primary btn-sm"}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>}
            <Card className={"mb-3"}>
                <ListGroup variant="flush">
                    {!comments && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(comments && comments.length == 0) &&<div className={'loading'}>
                        No comments yet
                    </div>}
                    {comments && comments
                        .filter((newsItem) => {
                            const user = props.usersMap.get(newsItem.userId);
                            return user && !user.socialDisabled
                        })
                        .map((comment: NewsItem, index: number) => (
                        props.currentUser && <CommentRow key={comment.id} user={props.currentUser} comment={comment} deleteComment={deleteComment}/>
                    ))}
                </ListGroup>
            </Card>
        </>
    )
}

export default LocationComments;
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
import "../../App.css";

interface Props {
    visitCount: number;
    visited: boolean;
    id: string,
    toggleVisited: (locationId: string) => void;
}

const getVisitedStyle = (visited: boolean) => {
    if(!visited) {
        return 'not-selected cursor-pointer font-1dot5em';
    } else {
        return 'white cursor-pointer font-1dot5em';
    }
}

function VisitElement(props: Props) {
    return (
        <div>
            <div>
                 <FontAwesomeIcon className={getVisitedStyle(props.visited)}
                                  onClick={() => props.toggleVisited(props.id)}
                                  icon={faCheckCircle} />
            </div>
            <div className={"text-center"}>{props.visitCount}</div>
        </div>
    )
}

export default VisitElement;
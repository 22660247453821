import React, {Component, useEffect, useState} from "react";
import {Card, ListGroup} from "react-bootstrap";
import {Location, NewsItem, Session, User} from "../types/Types";
import {collection, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";
import HubNav from "./HubNav";
import {NOTIFICATION_LIMIT} from "../util/Util";
import SessionRow from "../shared/SessionRow";

interface Props {
    usersMap: Map<string, User>;
    currentUser: User | undefined;
}

function Sessions(props: Props) {

    const [sessions, setSessions] = useState<Array<Session> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            await loadAllData();
        })();
    }, []);

    const loadAllData = async() => {
        // Load visit sessions
        let sessions: Array<Session> = [];
        const sessionOnceCollection = query(collection(db, 'sessions'),
            orderBy('checkInTime', 'desc'),
            limit(NOTIFICATION_LIMIT));
        const sessionOnceCollectionSnapshot = await getDocs(sessionOnceCollection);
        sessionOnceCollectionSnapshot.forEach((doc) => {
            let session = doc.data() as Session;
            sessions.push(session);
        });
        setSessions(sessions);
    }

    return (
        <>
            <HubNav />
            <Card className={"mb-3"}>
                <ListGroup variant="flush">
                    {!sessions && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(sessions && sessions.length == 0) &&<div className={'loading'}>
                        No sessions yet
                    </div>}
                    {sessions && sessions.filter(newsItem => {
                        const user = props.usersMap.get(newsItem.userId);
                        return user && !user.socialDisabled
                    }).map((session: Session, index: number) => (
                        <SessionRow displayName={true} displayLocation={true} user={props.usersMap.get(session.userId)} session={session} />
                    ))}
                </ListGroup>
            </Card>
        </>
    )
}

export default Sessions;
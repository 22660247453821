import React, {Component, useEffect, useRef, useState} from "react";
import {Location, User, Visit} from "../types/Types";
import UserVisits from "./UserVisits";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    locations: Array<Location> | undefined
}

function MyProfile(props: Props) {
    return (
        <UserVisits locations={props.locations} match={props.match}/>
    );
}

export default MyProfile;
import React from "react";
import "../nav/Navigation.css";
import { NavLink, RouteComponentProps } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function LeaderboardNav() {

    return (
        <Container>
            <Nav variant="tabs" className="mb-2 mr-auto sub-menu-nav">
                <>
                    <NavLink className="nav-link" to="/leaderboard/visits">
                        Visits
                    </NavLink>
                    <NavLink className="nav-link" to="/leaderboard/verified">
                        Verified <FontAwesomeIcon icon={faLocationDot} />
                    </NavLink>
                    <NavLink className="nav-link" to="/leaderboard/sessions">
                        Check-in Time
                    </NavLink>
                </>
            </Nav>
        </Container>
    )
}

export default LeaderboardNav;

import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import { Route, Router } from "react-router";
import history from './components/util/History';
import Locations from './components/locations/Locations';
import LeaderboardVisits from './components/leaderboard/LeaderboardVisits';
import Navigation from "./components/nav/Navigation";
import Home from './components/home/Home';
import {Switch} from "react-router";
import Header from './components/page/Header';
// @ts-ignore
import AuthEmail from './components/auth/AuthEmail';
import MapElement from "./components/map/MapElement";
import Admin from "./components/admin/Admin";
import Support from "./components/support/Support";
import UserProfile from "./components/users/UserProfile";
import AuthEmailResponse from "./components/auth/AuthEmailResponse";
import Settings from "./components/settings/Settings";
import {getAuth, onAuthStateChanged} from "@firebase/auth";
import {collection, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db} from "./firebase/FirebaseProvider";
import {Location, Session, User, Visit} from "./components/types/Types";
import Visits from "./components/hub/Visits";
import Comments from "./components/hub/Comments";
import Updates from "./components/hub/Updates";
import MyProfile from "./components/users/MyProfile";
import LocationComments from "./components/locations/profile/LocationComments";
import LocationVisits from "./components/locations/profile/LocationVisits";
// Google Analytics
import ReactGA from 'react-ga';
import {LOCATION_LIMIT, USERS_LIMIT} from "./components/util/Util";
import MyLocation from "./components/locations/profile/MyLocation";
import Sessions from "./components/hub/Sessions";
import UserVisits from "./components/users/UserVisits";
import UserSessions from "./components/users/UserSessions";
import LocationSessions from "./components/locations/profile/LocationSessions";
import LeaderboardVerified from "./components/leaderboard/LeaderboardVerified";
import LeaderboardSessions from "./components/leaderboard/LeaderboardSessions";
const TRACKING_ID = "G-27JNWX5874";
ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => {

    const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
    const [userSession, setUserSession] = useState<Session | undefined>(undefined);
    const [users, setUsers] = useState<Array<User>>([]);
    const [usersMap, setUsersMap] = useState<Map<string, User>>(new Map<string, User>());
    const [locations, setLocations] = useState<Array<Location>>([]);
    const [sessions, setSessions] = useState<Array<Session>>([]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        (async () => {
            const auth = getAuth();
            onAuthStateChanged(auth, async (currentUser) => {
                if (!currentUser) {
                    return;
                }
                const userCollection = query(collection(db, 'users'), where('authId', '==', currentUser.uid));
                const userCollectionSnap = await getDocs(userCollection);
                userCollectionSnap.forEach((userDoc) => {
                    let userToSet = userDoc.data() as User;
                    userToSet.id = userDoc.id;
                    setCurrentUser(userToSet);
                });
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const usersCollection = query(collection(db, 'users'),
                orderBy('visitCount', 'desc'),
                limit(USERS_LIMIT)
            );
            const usersCollectionSnapshot = await getDocs(usersCollection);
            let users: Map<string, User> = new Map<string, User>();
            let usersArray: Array<User> = new Array<User>();
            usersCollectionSnapshot.forEach((doc) => {
                let userToSet = doc.data() as User;
                userToSet.id = doc.id;
                users.set(userToSet.id, userToSet);
                usersArray.push(userToSet);
            });
            setUsers(usersArray);
            setUsersMap(users);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let locationsArray: Location[] = [];
            const locationsOnceCollection = query(collection(db, 'locations'),
                orderBy('name', 'asc'),
                limit(LOCATION_LIMIT));
            const locationsCollectionSnapshot = await getDocs(locationsOnceCollection);
            locationsCollectionSnapshot.forEach((doc) => {
                let tempLocation = doc.data() as Location;
                tempLocation.id = doc.id;
                if (!tempLocation.name) {
                    console.log('Location does not have a name: ' + doc.id);
                    tempLocation.name = 'Wetherspoons';
                }
                if(tempLocation.enabled == undefined || tempLocation.enabled) {
                    locationsArray.push(tempLocation);
                }
            });
            setLocations(locationsArray);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let sessions = new Array<Session>();
            const sessionsOnceCollection = query(collection(db, 'sessions'),
                where('checkOutTime', '>', new Date()),
                orderBy('checkOutTime', 'desc'),
                limit(LOCATION_LIMIT));
            const sessionsCollectionSnapshot = await getDocs(sessionsOnceCollection);
            sessionsCollectionSnapshot.forEach((doc) => {
                let tempSession = doc.data() as Session;
                tempSession.id = doc.id;
                sessions.push(tempSession);
            });
            setSessions(sessions);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if(!currentUser) {
                return;
            }
            const sessionsOnceCollection = query(collection(db, 'sessions'),
                where('userId', '==', currentUser.id),
                where('checkOutTime', '>', new Date()));
            const sessionsCollectionSnapshot = await getDocs(sessionsOnceCollection);
            sessionsCollectionSnapshot.forEach((doc) => {
                let tempSession = doc.data() as Session;
                tempSession.id = doc.id;
                setUserSession(tempSession);
            });

        })();
    }, [currentUser]);

    return (
      <Router history={history}>
        <Route render={(props) => <Header {...props} sessions={sessions} userSession={userSession} />}/>
        <Route component={Navigation} />
        <Container>
          <Switch>

              {/*No auth required*/}
              <Route exact path="/" component={AuthEmail} />
              <Route exact path="/auth/response" component={AuthEmailResponse} />
              <Route exact path="/home" render={(props) => <Home {...props} currentUser={currentUser} />}/>
              <Route exact path="/login" component={AuthEmail} />
              <Route exact path="/support" component={Support} />

              {/*Auth Required*/}
              <Route exact path="/pubs" render={(props) => <Locations {...props} locations={locations} />}/>
              <Route exact path="/map" render={(props) => <MapElement {...props} sessions={sessions} locations={locations} />}/>
              <Route exact path="/settings" render={(props) => <Settings {...props} currentUser={currentUser} />}/>
              <Route exact path="/admin/:id" component={Admin} />
              <Route exact path="/admin" component={Admin} />

              {/*Leaderboard specific*/}
              <Route exact path="/leaderboard/visits" render={(props) => <LeaderboardVisits {...props} users={users} currentUser={currentUser} />}/>
              <Route exact path="/leaderboard/verified" render={(props) => <LeaderboardVerified {...props} users={users} currentUser={currentUser} />}/>
              <Route exact path="/leaderboard/sessions" render={(props) => <LeaderboardSessions {...props} users={users} currentUser={currentUser} />}/>

              {/*User specific*/}
              <Route exact path="/profile/:id/visits" render={(props) => <UserVisits {...props} locations={locations} />}/>
              <Route exact path="/profile/:id/sessions" render={(props) => <UserSessions {...props} locations={locations} />}/>
              <Route exact path="/profile/:id/visits/me" render={(props) => <MyProfile {...props} locations={locations} />}/>

              {/*Location specific*/}
              <Route exact path="/location/:id/comments" render={(props) => <LocationComments {...props} currentUser={currentUser} usersMap={usersMap} />}/>
              <Route exact path="/location/:id/comments/me" render={(props) => <MyLocation {...props} currentUser={currentUser} usersMap={usersMap} />}/>
              <Route exact path="/location/:id/visits" render={(props) => <LocationVisits {...props} currentUser={currentUser} usersMap={usersMap} />}/>
              <Route exact path="/location/:id/sessions" render={(props) => <LocationSessions {...props} currentUser={currentUser} usersMap={usersMap} />}/>

              {/*Hub specific*/}
              <Route exact path="/hub/visits" render={(props) => <Visits {...props} usersMap={usersMap} />}/>
              <Route exact path="/hub/comments" render={(props) => <Comments {...props} usersMap={usersMap} />}/>
              <Route exact path="/hub/updates" render={(props) => <Updates {...props} usersMap={usersMap} />}/>
              <Route exact path="/hub/sessions" render={(props) => <Sessions {...props} currentUser={currentUser} usersMap={usersMap} />}/>

          </Switch>
        </Container>
      </Router>
  );
};

export default App;

import React from "react";
import "./Navigation.css";
import { NavLink, RouteComponentProps } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {User} from "../types/Types";
import {getAuth, onAuthStateChanged} from "@firebase/auth";
import {collection, doc, getDoc, getDocs, query, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";

interface Props extends RouteComponentProps {}

interface State {
  currentUser?: User | null;
}

class Navigation extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        this.setState({ currentUser: null });
        return;
      }
      const userCollection = query(collection(db, 'users'), where('authId', '==', currentUser.uid));
      const userCollectionSnap = await getDocs(userCollection);
      userCollectionSnap.forEach((userDoc) => {
        this.setState({ currentUser: userDoc.data() as User });
        return;
      });
    });
  }

  public render() {
    return (
      <Container>
        <Nav variant="pills" className="my-3 mr-auto">
          {this.state.currentUser &&
          <>
            <NavLink className="nav-link" to="/pubs">
              Pubs
            </NavLink>
            <NavLink className="nav-link" to="/leaderboard/visits">
              Leaderboard
            </NavLink>
            <NavLink className="nav-link" to="/map">
              Map
            </NavLink>
            <NavLink className="nav-link" to="/hub/visits">
              Hub
            </NavLink>
          </>}
          <div className="ml-auto">
            <NavLink className="nav-link" to="/support">
              Support
            </NavLink>
          </div>
          {/*<NavDropdown*/}
          {/*  className="ml-auto"*/}
          {/*  title="More"*/}
          {/*  id="nav-dropdown"*/}
          {/*>*/}
          {/*  <NavDropdown.Item onClick={this.navigateToSupport}>*/}
          {/*    Support*/}
          {/*  </NavDropdown.Item>*/}
          {/*</NavDropdown>*/}
        </Nav>
      </Container>
    );
  }

  private navigateToSupport = () => this.props.history.push("/support");

}

export default Navigation;

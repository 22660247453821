import React from "react";
import "../../nav/Navigation.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

interface Props {
    locationId: string;
}

function LocationNav(props: Props) {

    return (
        <Container>
            <Nav variant="tabs" className="mt-2 mb-2 mr-auto sub-menu-nav">
                <>
                    <NavLink className="nav-link" to={`/location/${props.locationId}/comments`}>
                        Comments
                    </NavLink>
                    <NavLink className="nav-link" to={`/location/${props.locationId}/visits`}>
                        Visits
                    </NavLink>
                    <NavLink className="nav-link" to={`/location/${props.locationId}/sessions`}>
                        Check-ins
                    </NavLink>
                </>
            </Nav>
        </Container>
    )
}

export default LocationNav;

import React, {Component} from "react";
import {db} from "../../firebase/FirebaseProvider";
import {getAuth, sendSignInLinkToEmail, onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import {Redirect} from "react-router";
import {collection, doc, addDoc, getDoc, getDocs, query, setDoc, where} from "@firebase/firestore";
import {User, Visit} from "../types/Types";
import {Card} from "react-bootstrap";

interface State {
    loading: boolean;
    authState: number;
    email: string;
    password: string;
    passwordStatus: number;
    feedback: string;
    name: string;
    register: boolean;
}

class AuthEmail extends Component<{}, State> {

    constructor(props: object) {
        super(props);

        this.state = {
            loading: true,
            authState: -1,
            email: '',
            name: '',
            register: false,
            password: '',
            passwordStatus: -1,
            feedback: ''
        }
    }

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, async (currentUser) => {
            if (!currentUser || !currentUser.email) {
                this.setState({authState: 0});
                return;
            }

            const authsRef = doc(db, 'auths', currentUser.uid);
            const authsSnap = await getDoc(authsRef);
            if (authsSnap.exists() && authsSnap.data().userId) {
                this.setState({authState: 2});
                return;
            }

            // User should only ever hit this once
            await setDoc(authsRef, {
                id: currentUser.uid,
                email: currentUser.email.toLowerCase()
            }, {merge: true});

            const userCollection = query(collection(db, 'users'), where('email', '==', currentUser.email.toLowerCase()));
            const userCollectionSnap = await getDocs(userCollection);
            if(userCollectionSnap.size > 1) {
                alert("An error has occurred, please contact support.");
                return;
            }
            userCollectionSnap.forEach((userDoc) => {

                let user: User = userDoc.data() as User;

                setDoc(authsRef, {
                    userId: userDoc.id
                }, {merge: true}).then(() => {

                    const usersRef = doc(db, 'users', userDoc.id);
                    setDoc(usersRef, {
                        authId: currentUser.uid
                    }, {merge: true}).then(() => {
                        window.location.assign(window.location + 'home');
                    });

                });

            });

            this.setState({authState: 0});
        });
    }

    login = async () => {
        if(!this.state.password) {
            this.loginWithEmail();
        } else {
            this.loginWithPassword();
        }
    }

    loginWithEmail = async () => {

        // Check to see if user actually exists before letting them login
        const userCollection = query(collection(db, 'users'), where('email', '==', this.state.email.toLowerCase()));
        const userCollectionSnap = await getDocs(userCollection);
        if(userCollectionSnap.size == 0) {
            alert("Sorry, you don't have an existing user profile, please register first");
            return;
        }

        const actionCodeSettings = {
            url: window.location + 'auth/response',
            handleCodeInApp: true
        };
        const auth = getAuth();
        sendSignInLinkToEmail(auth, this.state.email.toLowerCase(), actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('emailForSignIn', this.state.email.toLowerCase(),);
                this.setState({authState: 1});
            });
    }

    register = async (email: string, name: string) => {
        this.setState({password: ''});
        await this.createUserIfDoesNotExist(email, name);
        await this.login();
    }

    createUserIfDoesNotExist = async (email: string, name: string) => {

        if(!name) {
            name = 'Anonymous';
        }

        const usersRef = collection(db, 'users');
        const userCollection = query(usersRef, where('email', '==', email.toLowerCase()));
        const userCollectionSnap = await getDocs(userCollection);
        if(userCollectionSnap.size == 0) {
            await addDoc(usersRef, {
                name,
                email: email.toLowerCase()
            });
        }
    }

    loginWithPassword = async () => {

        // Check to see if user actually exists before letting them login
        const userCollection = query(collection(db, 'users'), where('email', '==', this.state.email.toLowerCase()));
        const userCollectionSnap = await getDocs(userCollection);
        if(userCollectionSnap.size == 0) {
            alert("Sorry, you don't have an existing user profile, please register first");
            return;
        }

        this.setState({passwordStatus: 1});
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.state.email.toLowerCase(), this.state.password)
            .then((userCredential) => {
                this.setState({authState: 1});
            })
            .catch((error) => {
                if(error.message.includes('wrong-password')) {
                    this.setState({passwordStatus: 0, feedback: "Incorrect password, please try again."});
                } else {
                    this.setState({passwordStatus: 0, feedback: error.message});
                }
            });
    }


    render() {
        return (
            <>
                {this.state.authState == -1 &&
                <div className={'loading'}>
                    Loading...
                </div>}

                {this.state.authState >= 0 &&
                <div className={""}>

                    <Card className={"mb-3"}>
                        <Card.Header>Welcome to Spoons' Tracker!</Card.Header>
                        <Card.Body>
                            <div>Welcome, it's time to get ticking off those Spoons'! Use this website to track your visits to Wetherspoon pubs.</div>
                            <div className={"mt-2"}>If you're new to the site please select register below and let us know your email address and name.</div>
                            <div className={"mt-2"}><small>If you have an existing <b>Facebook</b> login, please proceed to login with the same email address you used for your Facebook account. Contact support if your email address has changed.</small></div>
                        </Card.Body>
                    </Card>

                    {!this.state.register &&
                    <Card className={""}>
                        <Card.Header className={"mb-3"}>Login</Card.Header>
                            <div className={"col-12 col-md-8 col-lg-6"}>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Email</span>
                                    </div>
                                    <input type="email"
                                           name={"email"}
                                           onChange={e => this.setState({email: e.target.value})}
                                           className="form-control w-50"
                                           aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                </div>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Password</span>
                                    </div>
                                    <input type="password"
                                           name={"password"}
                                           onChange={e => this.setState({password: e.target.value})}
                                           className="form-control w-50"
                                           aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                </div>
                                {this.state.authState == 0 && this.state.passwordStatus == 0 && <div>
                                    <div className={"alert alert-danger p-2"}>
                                        <span>{this.state.feedback}</span>
                                    </div>
                                </div>}
                                {(this.state.authState == 1 && this.state.passwordStatus != 1) && <div className={"alert alert-success p-2 mb-0"}>Thanks, you'll now be sent an email containing a link. Please click on it to login to 'Spoons Tracker.</div>}
                            </div>
                        {(this.state.authState == 0 && this.state.passwordStatus == -1) &&
                        <div className={"col-12 col-md-8 col-lg-6"}>
                            <div className={"alert alert-info p-2 mb-0"}>No password? No problem. Just enter your email address and press login and you will receive a login link via email. Make a password on the settings page.</div>
                        </div>}
                        <div className={"p-3"}>
                            <div style={{float: "right"}}>
                                <button disabled={this.state.authState == 1} className={"btn btn-primary mr-1"} onClick={() => {this.login()}}>Login</button>
                                <button disabled={this.state.authState == 1} className={"btn btn-dark"} onClick={() => {this.setState({register: true})}}>Register</button>
                            </div>
                        </div>
                    </Card>}

                    {this.state.register &&
                    <Card className={""}>
                        <Card.Header className={"mb-3"}>Register</Card.Header>
                        <div className={"col-12 col-md-8 col-lg-6"}>
                            <div className={"mb-2"}>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Email</span>
                                    </div>
                                    <input type="text"
                                           name={"email"}
                                           onChange={e => this.setState({email: e.target.value})}
                                           className="form-control"
                                           aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                </div>
                            </div>
                            <div className={""}>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Name</span>
                                    </div>
                                    <input type="text"
                                           name={"name"}
                                           onChange={e => this.setState({name: e.target.value})}
                                           className="form-control w-50"
                                           aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                </div>
                            </div>
                            {(this.state.authState == 1 && this.state.passwordStatus != 1) && <div className={"alert alert-success p-2 mb-0"}>Thanks, you'll now be sent an email containing a link. Please click on it to login to 'Spoons Tracker.</div>}
                        </div>
                        <div className={"p-3"}>
                            <div style={{float: "right"}}>
                                <button disabled={this.state.authState == 1} className={"btn btn-dark mr-1"} onClick={() => {this.setState({register: false})}}>Login</button>
                                <button disabled={this.state.authState == 1} className={"btn btn-primary"} onClick={() => {this.register(this.state.email, this.state.name)}}>Register</button>
                            </div>
                        </div>
                    </Card>}

                </div>}

                {this.state.authState == 2 && <Redirect to="/home" />}
            </>
        )
    };
};

export default AuthEmail;

import React from "react";
import "../nav/Navigation.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";

interface Props {
    active: boolean
}

function VerificationTag(props: Props) {

    return (
        <>
            <div className={`user-tag d-inline-block ${props.active ? "active-session" : ""}`}>
                <FontAwesomeIcon className="animated zoomIn fa-solid" icon={faLocationDot}/>
            </div>
        </>
    )
}

export default VerificationTag;

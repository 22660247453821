import React from "react";
import {Location} from "../../types/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHammer, faHotel, faPencilAlt, faPlane, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

interface Props {
    location: Location | undefined
}

function LocationDetails(props: Props) {

    return (
        <>
            <div className={"location-header"}>
                {props.location && <div>
                    <div>{props.location.name}</div>
                    <div style={{fontSize: "0.5em"}}>
                        {props.location.address}
                    </div>
                    <div style={{fontSize: "0.5em"}}>
                        <div>
                            <span><b>{props.location.visitCount}</b> visits</span>
                            {props.location.airport && <small className="text-truncate">
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-plane" icon={faPlane} />
                            </small>}
                            {props.location.hotel && <small className="text-truncate">
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-hotel" icon={faHotel} />
                            </small>}
                            {props.location.pubIsClosed && <small className="text-truncate">
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-closed" icon={faTimes} />
                            </small>}
                            {(props.location.pubIsTemporaryClosed && !props.location.pubIsClosed) && <small className="text-truncate">
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-temporary" icon={faHammer} />
                            </small>}
                        </div>
                    </div>
                </div>}
                {!props.location && <div>...</div>}
            </div>
        </>
    );
}

export default LocationDetails;
import React, {Component} from "react";
import {Location, Visit} from "../types/Types";
import {Col, Row} from "react-bootstrap";
import "../../App.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHammer, faPlane, faHotel, faTimes } from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import VerificationTag from "../verification/VerificationTag";

interface Props {
    location: Location;
    index: number;
    visit: Visit | undefined;
}

function LocationRow(props: Props) {
    return (
        <div className="pub-row align-middle flex-nowrap">
            <Col className="d-flex flex-column align-items-start justify-content-center text-truncate">
                <div>
                    <Link to={`/location/${props.location.id}/comments`} className={"p-0"}>
                        <div className={"location-tag"}>{props.location.name}</div>
                    </Link>
                    {props.visit && props.visit.verified && <div className={"d-inline-block ml-1"}><VerificationTag active={false}/></div>}
                    {props.location.airport && <small className="text-truncate">
                        <FontAwesomeIcon className="ml-1 animated zoomIn icon-plane" icon={faPlane} />
                    </small>}
                    {props.location.hotel && <small className="text-truncate">
                        <FontAwesomeIcon className="ml-1 animated zoomIn icon-hotel" icon={faHotel} />
                    </small>}
                    {props.location.pubIsClosed && <small className="text-truncate">
                        <FontAwesomeIcon className="ml-1 animated zoomIn icon-closed" icon={faTimes} />
                    </small>}
                    {(props.location.pubIsTemporaryClosed && !props.location.pubIsClosed) && <small className="text-truncate">
                        <FontAwesomeIcon className="ml-1 animated zoomIn icon-temporary" icon={faHammer} />
                    </small>}
                </div>
                {props.location && (
                    <div>
                        <small className="text-truncate">
                            {props.location.address}
                        </small>
                    </div>
                )}
            </Col>
        </div>
    )
}

export default LocationRow;
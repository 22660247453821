import {Props} from "@fortawesome/react-fontawesome";
import LeaderboardVisits from "../leaderboard/LeaderboardVisits";
import React, {Component} from "react";
import {Card} from "react-bootstrap";

interface State {
    loading: boolean;
}

class Support extends Component<{}, State> {

    render() {
        return (
            <Card>
                <Card.Header>
                    <div>Support</div>
                </Card.Header>
                <Card.Body>
                    <p>If you would like to raise an issue, provide feedback or request a new feature, please email: <a href="mailto:spoonstracker.com@gmail.com">spoonstracker.com@gmail.com</a></p>
                    <p>GDPR - If you would like to delete your data, please email with a subject of "Delete": <a href="mailto:spoonstracker.com@gmail.com">spoonstracker.com@gmail.com</a></p>
                    <p>For our privacy policy please click <a href="https://www.termsfeed.com/live/2e457757-1243-4bef-87c4-894b0d25d664">here</a></p>
                    <p>If you would like to donate to the developers it would be greatly appreciated, and help provide the following:</p>
                    <ul>
                        <li>New features</li>
                        <li>Automated daily data backups</li>
                        <li>Automated pub updates from the official 'spoons website</li>
                        <li>General help and support</li>
                    </ul>
                    <p>For example: £5 is the monhtly cost of running a server for automatic data backups / data loads</p>
                    <div>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="SMDZ7R6VLCX3Q" />
                                    <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif"
                                           name="submit" alt="PayPal – The safer, easier way to pay online!" />
                                    <img alt="" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default Support;
import React, {Component, useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {getAuth, onAuthStateChanged, updatePassword} from "@firebase/auth";
import history from "../util/History";
import {collection, doc, getDoc, getDocs, query, setDoc, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {User} from "../types/Types";

interface Props {
    currentUser: User | undefined
}

function Settings(props: Props) {

    const [password, setPassword] = useState<string>();
    const [feedback, setFeedback] = useState<string>();
    const [passwordStatus, setPasswordStatus] = useState<number>(-1);

    const savePassword = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if(user && password) {
            updatePassword(user, password).then(() => {
                setFeedback("Password successfully saved.")
                setPasswordStatus(2);
            }).catch((error) => {
                if(error.message.includes("requires-recent-login")) {
                    setFeedback("To set a password you need to have logged in more recently, please log out and log back in, and then try again.");
                    setPasswordStatus(1);
                } else {
                    setFeedback(error.message);
                    setPasswordStatus(1);
                }
            });
        }
    }

    const isValidPassword = (): boolean => {
        return password != null && password.length > 5;
    }

    const toggleSocial = async(toggleState: boolean) => {
        if(!props.currentUser) {
            return;
        }
        const usersRef = doc(db, 'users', props.currentUser.id);
        await setDoc(usersRef, {
            socialDisabled: toggleState
        }, {merge: true});
        window.location.assign(window.location + "");
    }

    return (
        <>
            {props.currentUser && props.currentUser.admin && <>
                <Card className={"mb-3"}>
                    <Card.Header>
                        <div>Pub admin</div>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            <div>Click the below button to add a pub. Click on any of the <FontAwesomeIcon className="ml-1 mr-1 animated zoomIn icon-pen cursor-pointer" icon={faPencilAlt} /> in the pub list to edit.</div>
                            <div className={"float-right"}>
                                <Link to={`/admin`} className="cursor-pointer">
                                    <button className={"btn btn-primary"}>Add pub</button>
                                </Link>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </>}
            <>
                <Card className={"mb-3"}>
                    <Card.Header>
                        <div>Password</div>
                    </Card.Header>
                    <Card.Body>
                        <div>If you would like to set a password for your account please do so below. To sign in using a password please enter it on the login screen.</div>
                        <div className={"mb-3"}>Your password should have a minimum of 6 characters.</div>
                        <div className={"col-12 col-md-8 col-lg-6 pr-0 pl-0"}>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-default">Password</span>
                                </div>
                                <input type="password"
                                       name={"password"}
                                       onChange={e => setPassword(e.target.value)}
                                       className="form-control w-50"
                                       aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                            </div>
                            {passwordStatus > 0 && <div>
                                {passwordStatus == 2 && <div className={"alert alert-success p-2"}>
                                    <span>{feedback}</span>
                                </div>}
                                {passwordStatus == 1 && <div className={"alert alert-danger p-2"}>
                                    <span>{feedback}</span>
                                </div>}
                            </div>}
                        </div>
                        <div className={"float-right"}>
                            <button disabled={!isValidPassword()} className={"btn btn-primary"} onClick={() => {savePassword()}}>Save</button>
                        </div>
                    </Card.Body>
                </Card>
            </>
            {props.currentUser && <>
                <Card className={"mb-3"}>
                    <Card.Header>
                        <div>Disable social features</div>
                    </Card.Header>
                    <Card.Body>
                        {!props.currentUser.socialDisabled && <div>
                            <span>Your social participation setting is switched <b>ON</b>.</span>
                            <div>If you would like to disable participation in the social features 'Leaderboard', 'Hub' and 'Check In', please click the button below.</div>
                        </div>}
                        {props.currentUser.socialDisabled && <div>
                            <span>Your social participation setting is switched <b>OFF</b>.</span>
                            <div>If you would like to participate in the social features 'Leaderboard', 'Hub' and 'Check In', please click the button below.</div>
                        </div>}
                        {props.currentUser && <div className={"float-right"}>
                            {props.currentUser.socialDisabled && <button className={"btn btn-primary"} onClick={() => {toggleSocial(false)}}>Re-enable</button>}
                            {!props.currentUser.socialDisabled && <button className={"btn btn-secondary"} onClick={() => {toggleSocial(true)}}>Disable</button>}
                        </div>}
                    </Card.Body>
                </Card>
            </>}
        </>
    )
}

export default Settings;
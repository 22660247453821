import React, {Component} from "react";
import {db} from "../../firebase/FirebaseProvider";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import {Redirect} from "react-router";
import history from "../util/History";
import {collection, doc, addDoc, getDoc, getDocs, query, setDoc, where} from "@firebase/firestore";
import {User, Visit} from "../types/Types";
import {Card} from "react-bootstrap";

interface State {
    authState: number;
}

class AuthEmailResponse extends Component<{}, State> {

    constructor(props: object) {
        super(props);

        this.state = {
            authState: 0
        }
    }

    componentDidMount() {
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            let emailToSignInWith: string = '';
            if(email != null) {
                emailToSignInWith = email;
            }
            signInWithEmailLink(auth, emailToSignInWith, window.location.href)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    this.setState({authState: 1});
                });
        } else {
            this.setState({authState: 1});
        }
    }

    render() {
        return (
            <>
                <div className={'loading'}>
                    Loading...
                </div>
                {this.state.authState == 1 && <Redirect to="/" />}
            </>
        )
    };
};

export default AuthEmailResponse;

export function addAnchorTags (input: string): string  {
    const regex = /((?:https?|ftp):\/\/[\w\-_]+(?:\.[\w\-_]+)+[\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])\b/gi;
    return input.replace(regex, '<a href="$1">$1</a>');
}

export function sanitizeHtmlString(htmlString: string): string | null {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string into a DocumentFragment
    const doc = parser.parseFromString(htmlString, "text/html");
    // Use the textContent property to get the sanitized string
    const sanitizedString = doc.documentElement.textContent;

    if(!sanitizedString) {
        return "";
    }

    return sanitizedString;
}

export function getDate(lastModified: Date): string {
    var now: any = lastModified;
    const date: Date = new Date(now.seconds * 1000);
    return date.toDateString();
}

export function getTime(lastModified: Date): string {
    var now: any = lastModified;
    const date: Date = new Date(now.seconds * 1000);
    return date.toTimeString().substring(0, 5);
}

export function getDatePlusHours(now: Date, hours: number): Date {
    return new Date(now.getTime() + (hours * 60 * 60 * 1000));;
}

export function formatMetres(metres: number): string {
    if (metres < 1) {
        return `${metres * 100} centimetres`;
    } else if (metres < 1000) {
        return `${metres} metres`;
    } else {
        return `${(metres / 1000).toFixed(1)} kilometres`;
    }
}

export const LOCATION_LIMIT = 2000;
export const USERS_LIMIT = 2000;
export const NOTIFICATION_LIMIT = 100;

export function addNumberSuffix(number: number): string {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return "th";
    }

    switch (lastDigit) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

export function formatTime(hours: number): string {
    if (hours < 24) {
        return `${hours} ${hours !== 1 ? 'hours' : 'hour'}`;
    }

    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    let result = '';
    if (days > 1) {
        result += `${days} days`;
    } else {
        result += `${days} day`;
    }

    if (remainingHours > 0) {
        result += ` ${Math.floor(remainingHours)} ${remainingHours !== 1 ? 'hrs' : 'hr'}`;
    }

    return result;
}

import React from "react";
import "../../nav/Navigation.css";
import {Link} from "react-router-dom";
import {User, Visit} from "../../types/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import VerificationTag from "../../verification/VerificationTag";


interface Props {
    user: User | undefined
    visit: Visit | undefined
}

function UserTag(props: Props) {

    return (
        <>
            {props.user && <Link to={`/profile/${props.user.id}/visits`} className={"p-0 mr-1"}>
                <div className={"user-tag"}>
                    <div className={"d-inline-block"}>{props.user.name}</div>
                </div>
            </Link>}
            {props.visit && props.visit.verified && <div className={"d-inline-block"}>
                <VerificationTag active={false}/>
            </div>}
        </>
    )
}

export default UserTag;

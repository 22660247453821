import React from "react";
import "../../nav/Navigation.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface Props {
    image: string,
    description: string,
    class: String
}

function ImageSymbol(props: Props) {

    return (
        <>
            <div className={"user-tag d-inline-block mr-1"}>
                <img style={{width: "20px"}} src={props.image}/>
                <div className={"d-inline-block ml-2"}>{props.description}</div>
            </div>
        </>
    )
}

export default ImageSymbol;
